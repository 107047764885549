import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ViewportScroller } from '@angular/common';
import { CssRoles } from '@common/constants';
import { User } from '@common/entities';
import { NavigationFragments } from '@common/enums';
import { ContextProvider } from '@common/ui/shared-components';
import { FscdIntakeApplication, FscdIntakeApplicationErrors } from '@fscd-intake/entities';
import { FiGraphqlService } from '../services/fi-graphql.service';
import { CHILD_INFO, PARENTAL_INFO, REVIEW, SERVICES } from './application.steps';
import { BaseSaveComponent } from './base-save/base-save.component';
import { PageNavigation } from './page-navigation';
import { ChildInfoPageComponent } from './child-info-page/child-info-page.component';

@Component({
  selector: 'fi-application-page',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true,
        displayDefaultIndicatorType: false,
      },
    },
  ],
})
export class ApplicationComponent implements OnInit, AfterViewInit, OnDestroy {
  steps = [
    {
      ...CHILD_INFO,
    },
    {
      ...PARENTAL_INFO,
    },
    {
      ...SERVICES,
    },
    {
      ...REVIEW,
    },
  ];
  application: FscdIntakeApplication = { isSubmitted: false };
  selectedStepIndex = 0;
  orientation = 'horizontal';
  makeSticky = false;
  private orientationChangeSubject = new BehaviorSubject('horizontal');
  private stepperExpanded = true;
  disableSubmit = false;
  disableSave = false;
  isSubmitted;
  stepperInitialized = false;
  allApplicationErrors;
  currentPageComponent: BaseSaveComponent;
  isAuthenticated = false;
  isSubmitting = false;
  showDeveloperTools = false;
  profile$;
  profile: User = null;

  //this prevents the steps from displaying the edit/pencil icon after being visited.  Instead the step number remains
  @ViewChild('horizontalStepper') horizontalStepper: MatStepper;
  @ViewChild('verticalStepper') verticalStepper: MatStepper;
  @ViewChild(RouterOutlet) routerOutlet: RouterOutlet;
  private destroy$ = new Subject<void>();

  get isSingleButtonVisible() {
    return this.selectedStepIndex === 0 && !this.isSubmitted;
  }
  constructor(
    public apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private toasterService: ToastrService,
    private graphqlService: FiGraphqlService,
    private contextProvider: ContextProvider = null,
    private viewportScroller: ViewportScroller
  ) {}

  async ngOnInit() {
    if (this.contextProvider) {
      this.profile$ = this.contextProvider.getCurrentUser();
      this.profile$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (!this.profile) {
          this.profile = data;
          const isAdmin = this.profile?.roles?.some((role) => role == CssRoles.CSSAdmin.code);
          // check query params to show developer tools for admin only
          if (this.route.snapshot.queryParams && isAdmin) {
            this.showDeveloperTools = this.route.snapshot.queryParamMap.has('debug');
          }
        }
      });
    }

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      // this is not an exhaustive url check, but it matches current routes
      this.selectedStepIndex = this.activeSteps.findIndex(
        (s) => e.urlAfterRedirects.includes(`/${s.url}`) || e.urlAfterRedirects.includes(`/${s.url}/`)
      );
    });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.subscribeToSaveButtonState();
    });

    this.router.events.pipe();

    this.breakpointObserver
      // .observe('(max-width: 960px)') //matches $breakpoint-md in scss
      .observe('(max-width: 600px)') //matches $breakpoint-600: in scss;
      .pipe(takeUntil(this.destroy$))

      .subscribe((state: BreakpointState) => {
        this.orientation = state.matches ? 'vertical' : 'horizontal';
        this.orientationChangeSubject.next(this.orientation);
      });

    //subscribing because this isnt being passed to presentation component, it is being used to fetch other data
    const { selectedApplicationId, isSubmitted } = await this.graphqlService.getSelectedApplication();
    this.isSubmitted = isSubmitted;
    if (selectedApplicationId) {
      this.currentPageComponent = this.getCurrentPageComponent() as BaseSaveComponent;

      this.graphqlService
        .getFullApplication(selectedApplicationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((appInfo) => {
          if (appInfo) {
            if (!this.isSubmitted) {
              this.isSubmitted = appInfo?.isSubmitted;
            }
            this.application = appInfo;
            this.allApplicationErrors = this.currentPageComponent.collectAllErrors(appInfo);
            this.observeViewPort();
            //if we're on the first step initialize
            //if we're past the first step and stepper has not yet been initialized, we need to initialize. (happens on creation of key-questions)
            if (this.selectedStepIndex === 0 || (this.selectedStepIndex > 0 && !this.stepperInitialized)) {
              //set stepper
              this.setStepper();
            }

            const appErrors = this.application.applicationErrors as FscdIntakeApplicationErrors;
            if (appErrors && !this.application?.isSubmitted) {
              this.steps.forEach(
                (step) =>
                  (step.hasError = appErrors[step.code + 'Errors'] && appErrors[step.code + 'Errors'].length > 0)
              );
            }

            this.route.url.subscribe(() => {
              setTimeout(() => {
                this.selectedStepIndex = this.activeSteps.findIndex(
                  (s) => s.url == this.route.snapshot.firstChild.url[0].path
                );
                this.initializeStepper();
              });
            });
          }
          this.disableSubmit = this.steps.some((s) => s.hasError && s.active);
        });
    }

    if (!this.isSubmitted) {
      //check if there is a #ScrollToError fragment
      this.scrollToPosition();
    }

    this.subscribeToSaveButtonState();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.orientationChangeSubject.pipe(takeUntil(this.destroy$)).subscribe((orientation) => {
      if (!this.application?.isSubmitted) {
        this.horizontalStepper.selectedIndex = this.selectedStepIndex;
        this.verticalStepper.selectedIndex = this.selectedStepIndex;

        if (orientation === 'vertical') {
          const expansionPanel = document.querySelector('.stepper-expansion-panel') as HTMLElement;
          expansionPanel.style.maxHeight = expansionPanel.scrollHeight + 'px';
        }
      }
    });
    this.observeViewPort();
  }

  subscribeToSaveButtonState() {
    this.disableSave = false;
    const component = this.getCurrentPageComponent();
    if (component instanceof ChildInfoPageComponent) {
      component.disableSave
        .pipe(takeUntil(this.destroy$))
        .subscribe(a => {
          this.disableSave = a;
        });
    }
  }

  private setStepper() {
    this.steps.find((step) => step.code === CHILD_INFO.code).active = true;
    this.steps.find((step) => step.code === PARENTAL_INFO.code).active = true;
    this.steps.find((step) => step.code === SERVICES.code).active = true;
    this.steps.find((step) => step.code === REVIEW.code).active = !this.application.isSubmitted;

    this.initializeStepper();
  }

  observeViewPort() {
    const targetToObserve = document.querySelector('.stepperContainer');
    const observer = new IntersectionObserver(
      ([e]) => {
        if (this.orientation === 'vertical') {
          if (e.isIntersecting) {
            this.stepperExpanded = true;
            this.makeSticky = false;
          } else {
            this.stepperExpanded = false;
            this.makeSticky = true;
          }
          this.expandCollapseStepper();
        }
      },
      {
        threshold: [0.4],
        root: null,
      }
    );
    observer.observe(targetToObserve);
  }

  expandCollapseStepper() {
    const expansionPanel = document.querySelector('.stepper-expansion-panel') as HTMLElement;
    const stepElements = document.querySelectorAll('.mat-step') as NodeListOf<HTMLElement>;

    //Show all steps if the stepper is not sticky or if it has been expanded
    if (stepElements) {
      if (!this.makeSticky || this.stepperExpanded) {
        stepElements.forEach((element) => (element.style.display = 'block'));
      } else {
        stepElements.forEach((element) => {
          if (element.firstElementChild.getAttribute('aria-selected') === 'false') {
            element.style.display = 'none';
          }
        });
      }
    }

    if (expansionPanel) {
      if (this.stepperExpanded) {
        expansionPanel.style.maxHeight = expansionPanel.scrollHeight + 'px';
      } else {
        expansionPanel.style.maxHeight = null;
      }
    }
  }

  doSave(nextStepIndex?: number) {
    //1 min left, ensure token is refreshed to extend session
    //this.extendSessionTimeout();
    // HACK: override assets icon to done if navigating to review page and assets is notVisited
    // this will be addressed when stepper persistence is implemented
    const originalStepIndex = nextStepIndex;

    //some extra logic if nextStepIndex is null, assume save current step
    let saveCurrentStepOnly = false;
    if (nextStepIndex === null) {
      saveCurrentStepOnly = true;
      nextStepIndex = this.selectedStepIndex;
    }

    if (this.selectedStepIndex === nextStepIndex && !saveCurrentStepOnly) {
      //nothing to do, we're already on this page!
      return;
    }

    this.currentPageComponent = this.getCurrentPageComponent() as BaseSaveComponent;
    const canSave = this.checkSave(this.currentPageComponent);
    if (!canSave) {
      return;
    }
    this.isSubmitting = nextStepIndex === this.activeSteps.length && originalStepIndex !== null; //if null is passed in as a step we don't want to trigger submit

    if (!this.application?.isSubmitted) {
      //not submitted. need to save or submit
      const onReviewStep = this.selectedStepIndex === this.activeSteps.length - 1;
      const doSubmit = onReviewStep && this.isSubmitting;

      const navigateUrl = !doSubmit && nextStepIndex !== null ? this.activeSteps[nextStepIndex]?.url : null;
      this.saveSubmitNavigate(doSubmit, navigateUrl);
    } else if (originalStepIndex !== null) {
      //view-submission. navigate only
      //also need to maintain scroll position
      this.selectedStepIndex = nextStepIndex;
      const position = this.viewportScroller.getScrollPosition();
      this.router.navigate([this.activeSteps[this.selectedStepIndex].url], {
        relativeTo: this.route,
      });
      setTimeout(() => {
        this.scrollToPosition(position);
      });
    }
  }

  getCurrentPageComponent() {
    return this.routerOutlet.isActivated ? this.routerOutlet.component as BaseSaveComponent : {};
  }

  back() {
    this.doSave(this.selectedStepIndex - 1);
    this.selectedStepIndex--;
  }

  next() {
    const component = this.routerOutlet.component as PageNavigation;
    const moveNext = component.next();
    if (moveNext) {
      this.doSave(this.selectedStepIndex + 1);
    }
    this.selectedStepIndex++;
  }

  submit() {
    this.doSave(this.selectedStepIndex + 1);
  }

  checkSave(pageComponent: BaseSaveComponent) {
    if (!pageComponent) {
      //toast an error message so we know something is wrong, as opposed to suppressing this unexpected behavior.
      this.toasterService.error('An unexpected error has occurred.', undefined, {
        disableTimeOut: false,
        timeOut: 5000,
      });
      return false;
    }
    if (pageComponent.blockSaveMessage) {
      //if there is a block save message active, toast the message and do not save.
      this.toasterService.warning(pageComponent.blockSaveMessage, undefined, {
        disableTimeOut: false,
        timeOut: 5000,
      });
      return false;
    }
    return true;
  }

  saveSubmitNavigate(doSubmit = false, navigateUrl: string = null) {
    const currentPage = this.activeSteps[this.selectedStepIndex];
    this.currentPageComponent
      .save(doSubmit)
      .then(() => {
        //do nav
        if (doSubmit) {
          //submit and auto-navigate to first step
          // this.router.navigate(['application', this.application.id, this.activeSteps[0].url], {
          //   queryParams: { complete: 'true' },
          // });
          this.router.navigate([this.activeSteps[0].url], { relativeTo: this.route });
        } else if (navigateUrl) {
          //save and navigate
          this.router.navigate([navigateUrl], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
          });
        } else {
          //save and stay
          this.currentPageComponent.ngOnInit();
        }
      })
      .catch((e) => {
        if (this.showDeveloperTools) {
          console.log(e);
        }
        this.isSubmitting = false;
        this.toasterService.error(`There was a problem saving ${currentPage.name}`, null, {
          disableTimeOut: true,
        });
      });
  }

  initializeStepper() {
    setTimeout(() => {
      //this will mark the states of the previous steps to done if there are no errors
      const completedSteps = [];
      const incompleteSteps = [];
      this.activeSteps.forEach((activeStep) => {
        const errorLabel = `${activeStep.code}Errors`;
        let sectionErrors = this.allApplicationErrors ? this.allApplicationErrors[errorLabel] : null;
        if (!sectionErrors) {
          sectionErrors = [];
        }
        if (sectionErrors) {
          if (sectionErrors.length === 0) {
            //check if objects are null
            let hasData = true;
            if (activeStep.code === REVIEW.code && !this.application?.review) {
              const reviewStepIndex = this.activeSteps.length - 1;
              if (this.selectedStepIndex < reviewStepIndex) {
                hasData = false;
              }
            }
            if (hasData) {
              completedSteps.push(activeStep.name);
            }
          } else if (sectionErrors.length > 0) {
            // TODO remove incompleteSteps ?
            incompleteSteps.push(activeStep.name);
          }
        }
      });

      if (this.horizontalStepper?.steps) {
        this.horizontalStepper.steps.forEach((step, idx) => {
          // assign the doSave event to each step
          step.select = async () => this.doSave(idx);

          if (this.selectedStepIndex === idx) {
            step.state = 'edit';
          }
          if (completedSteps.indexOf(step.label) >= 0 && this.selectedStepIndex !== idx) {
            step.state = 'done';
          }
        });
        this.stepperInitialized = true;
      }

      if (this.verticalStepper?.steps) {
        this.verticalStepper.steps.forEach((step, idx) => {
          step.select = async () => this.doSave(idx);
          if (this.selectedStepIndex === idx) {
            step.state = 'edit';
          }
          if (completedSteps.indexOf(step.label) >= 0 && this.selectedStepIndex !== idx) {
            step.state = 'done';
          }
        });
        this.stepperInitialized = true;
      }
    }, 0);
  }

  get activeSteps() {
    return this.steps.filter((step) => step.active);
  }

  onStepperIndexChange($event: StepperSelectionEvent) {
    // override the state to edit when the step is selected since it will default to an error state if there are errors
    $event.selectedStep.state = 'edit';
  }

  onStepperIndexChangeForView($event: StepperSelectionEvent) {
    $event.selectedStep.state = 'view';
  }

  getFirstErrorElement(): HTMLElement {
    const firstInvalidControl = document.querySelector(`
      common-date-picker.ng-invalid,
      input.ng-invalid,
      mat-select.ng-invalid,
      mat-radio-group.ng-invalid input,
      has-error,
      .file-upload-container.ng-invalid,
      .has-error
    `) as HTMLElement;
    return firstInvalidControl?.closest(
      'common-form-control, common-fieldset-control, common-document-upload, .form-control'
    );
  }

  scrollToPosition(position?: [number, number]) {
    setTimeout(() => {
      if (position) {
        this.viewportScroller.scrollToPosition(position);
      } else {
        const fragment = this.route.snapshot?.fragment;
        if (fragment) {
          const headerOffset = 55;
          let scrollToElem = document.querySelector('#' + fragment);
          if (fragment === NavigationFragments.ScrollToFirstError) {
            scrollToElem = this.getFirstErrorElement();
          }
          if (scrollToElem) {
            window.scrollTo({
              top: scrollToElem.getBoundingClientRect().top - headerOffset,
              behavior: 'smooth',
            });
          }
        }
      }
    });
  }

  toggleStepperExpanded() {
    this.stepperExpanded = !this.stepperExpanded;
    this.expandCollapseStepper();
  }
}
