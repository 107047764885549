<div *ngIf="!isSubmitted">
  <fi-child-info
    [applicationId]="applicationId"
    [childInfo]="childInfo$ | async"
    [hasErrors]="hasErrors$ | async"
    (formError)="onFormError()"
    (formUpdated)="onFormUpdated($event)"
    (blockSaveMessage)="setBlockSaveMessage($event)"
    (disableSaveButton)="setSaveButtonStatus($event)"
  >
  </fi-child-info>
</div>
