import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisplayMode } from '@common/constants';
import { Document, User } from '@common/entities';
import { ContextProvider, FileService } from '@common/ui/shared-components';
import { ValidationUtils } from '@common/utils';
import {
  FiChild,
  FiParent,
  FiReview,
  FiServices,
  FscdIntakeApplication,
  FscdIntakeApplicationErrors,
  HALF_HOUR_SEC,
} from '@fscd-intake/entities';
import { AuthenticationService } from '@govalta-emu/keycloak-auth-service';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiGraphqlService } from '../../services/fi-graphql.service';

@Component({
  selector: 'fi-base-save',
  template: '<div>Dont need a template!</div>',
})
export class BaseSaveComponent implements OnInit, OnDestroy {
  applicationId: string;
  applicationInfo: FscdIntakeApplication;
  applicationInfoSubscription;
  displayMode: DisplayMode;
  isAuthenticated = false;
  blockSaveMessage = null;
  profile: User;
  profile$;
  _destroy$ = new Subject<void>();
  disableSave  = new Subject<boolean>();

  constructor(
    public apollo: Apollo,
    public fileService: FileService,
    public authenticationService: AuthenticationService,
    public contextProvider: ContextProvider,
    public fiGraphqlService: FiGraphqlService,
    public toasterService: ToastrService = null
  ) {}

  async ngOnInit() {
    if (this.authenticationService) {
      //NOTE: isLoggedIn() is causing a lot of problems in the application and requires us
      // to turn off ChangeDetectionStrategy.OnPush if we do a super.ngOnInit() to check if a user is authenticated
      // turning off ChangeDetectionStrategy.OnPush is causing other things to error.
      // this.isAuthenticated = await this.authenticationService.isLoggedIn();

      // as a resolution isAuthenticated() was created as a synchronous method that checks if a user has a valid user token
      // and that the user token has not yet expired.
      this.isAuthenticated = this.authenticationService.isAuthenticated();
    }

    //NOTE: Not sure why it is changed. I have reverted to use the original code with observable
    // if (this.contextProvider) {
    //   this.profile = await firstValueFrom(this.contextProvider.getCurrentUser());
    //   //this.profile$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
    // }

    if (this.contextProvider) {
      this.profile$ = this.contextProvider.getCurrentUser();
      this.profile$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
        if (!this.profile) {
          this.profile = data;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  save(doSubmit?: boolean): Promise<boolean | FscdIntakeApplication> {
    if (this.isAuthenticated) this.authenticationService.refreshToken(HALF_HOUR_SEC);
    return Promise.resolve(true);
  }

  deleteDocuments(documents: Document[], deleteAll = false, category = '') {
    if (documents) {
      const documentCount = documents.length - 1;
      for (let i = documentCount; i >= 0; i--) {
        if (deleteAll || documents[i].isDeleted || documents[i].documentCategory === category) {
          this.fileService.deleteFile(documents[i].key, this.applicationId);
          documents.splice(i, 1);
        }
      }
    }
  }

  updateSelectedApplication(applicationId) {
    this.fiGraphqlService.updateSelectedApplication(applicationId);
  }

  async getSelectedApplication() {
    const { selectedApplicationId, isSubmitted } = await this.fiGraphqlService.getSelectedApplication();
    return { selectedApplicationId, isSubmitted };
  }

  async getApplication() {
    const { selectedApplicationId } = await this.getSelectedApplication();
    return await firstValueFrom(this.fiGraphqlService.getFullApplication(selectedApplicationId));
  }

  async getProfile() {
    return await firstValueFrom(this.contextProvider.getCurrentUser());
  }

  displayStaticToastError(msg: string) {
    this.toasterService.error(msg, undefined, {
      disableTimeOut: true,
    });
  }

  setBlockSaveMessage(msg?: string) {
    this.blockSaveMessage = msg;
  }

  setSaveButtonStatus(disabled: boolean) {
    this.disableSave.next(disabled);
  }

  collectAllErrors(application: FscdIntakeApplication) {
    const applicationErrors = new FscdIntakeApplicationErrors();
    if (application) {
      applicationErrors.childErrors = ValidationUtils.validateEntity(FiChild, application.child ?? new FiChild());
      applicationErrors.parentGuardianErrors = ValidationUtils.validateEntity(
        FiParent,
        application.parent ?? new FiParent()
      );
      applicationErrors.servicesErrors = ValidationUtils.validateEntity(
        FiServices,
        application.services ?? new FiServices()
      );
      applicationErrors.reviewErrors = ValidationUtils.validateEntity(FiReview, application.review ?? new FiReview());
    }
    return applicationErrors;
  }
}
