import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  DocumentsUpload,
  FiDocumentCategories,
  FiLegalStatus,
  FiLivingArrangements,
  Genders,
  SELECT_ONE_TEXT,
} from '@common/constants';
import { AlbertaAddress, Document } from '@common/entities';
import { PersonalIdentifierValidator } from '@common/ui/validation';
import { FiChildInfoLabels } from '@common/labels';
import { FiChild, ModifiedYesNoOptionsUnsureYes } from '@fscd-intake/entities';
import { BaseStepComponent } from '../base-step/base-step.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fi-child-info',
  templateUrl: './child-info.component.html',
  styleUrls: ['./child-info.component.scss'],
})
export class ChildInfoComponent extends BaseStepComponent implements OnInit, OnDestroy {
  isReadOnly = true;
  genderList = Genders;
  legalStatusList = FiLegalStatus;
  livingArrangementList = FiLivingArrangements;
  selectOneText = SELECT_ONE_TEXT;
  formChangeSubscription;
  identityDocuments = [] as Document[];
  medicalDocuments = [] as Document[];
  additionalDocuments = [] as Document[];
  fiChildInfoLabels = FiChildInfoLabels;
  customRadioOptions = ModifiedYesNoOptionsUnsureYes;
  confirmedDiagnosisSubscription: Subscription;
  awaitingDiagnosisSubscription: Subscription;
  characterCountConfirmedDiagnosis = 0;
  characterCountAwaitingDiagnosis = 0;

  @Input() applicationId: string;
  _childInfo: FiChild;
  @Input() set childInfo(value: FiChild) {
    this._childInfo = value;
    if (value) {
      this.setFormValue();
    }
  }

  @Input() set hasErrors(value: boolean) {
    if (value) {
      this.formGroup.markAllAsTouched();
      this.cdr.detectChanges();
    }
  }

  @Output() formError = new EventEmitter();
  @Output() formUpdated = new EventEmitter();

  _dateMax = new Date();
  _dateMin = new Date(this._dateMax.getFullYear() - 18, this._dateMax.getMonth(), this._dateMax.getDate() - 1);

  medicalDocumentsFormControl = this.fb.control<Document[]>([]);
  medicalDocumentsNeeded = {
    medical: FiDocumentCategories.child.medical,
  } as DocumentsUpload;
  identityDocumentsFormControl = this.fb.control<Document[]>([]);
  identityDocumentsNeeded = {} as DocumentsUpload;
  additionalDocumentsFormControl = this.fb.control([]);
  additionalDocumentsNeeded = {
    additional: FiDocumentCategories.child.additional,
  } as DocumentsUpload;

  formGroup = this.fb.group({
    firstName: this.fb.control('', [Validators.required, PersonalIdentifierValidator.legalNameValidator]),
    middleName: this.fb.control('', PersonalIdentifierValidator.legalNameValidator),
    lastName: this.fb.control('', [Validators.required, PersonalIdentifierValidator.legalNameValidator]),
    preferredName: this.fb.control('', [PersonalIdentifierValidator.legalNameValidator]),
    birthdate: this.fb.control<Date>(null),
    gender: this.fb.control<string>(null, Validators.required),
    hasPrevApplication: this.fb.control<boolean>(null, Validators.required),
    hasPrevClosedWithin90: this.fb.control<string>(null),
    residentialAddress: this.fb.control<AlbertaAddress>(null, Validators.required),
    hasSameMailingAddress: this.fb.control<boolean>(null, Validators.required),
    mailingAddress: this.fb.control<AlbertaAddress>(null),
    citizenImmigrationStatus: this.fb.control<string>(null, Validators.required),
    livingArrangements: this.fb.control<string>(null, Validators.required),
    hasConfirmedDiagnosis: this.fb.control<boolean>(null, Validators.required),
    confirmedDiagnosis: this.fb.control('', Validators.required),
    isAwaitingDiagnosis: this.fb.control<boolean>(null, Validators.required),
    waitingDiagnosis: this.fb.control('', Validators.required),
    identityDocuments: this.identityDocumentsFormControl,
    medicalDocuments: this.medicalDocumentsFormControl,
    additionalDocuments: this.additionalDocumentsFormControl,
  });

  constructor(fb: FormBuilder, private cdr: ChangeDetectorRef, public route: ActivatedRoute) {
    super(route, fb);
  }

  ngOnInit(): void {
    this.formChangeSubscription = this.formGroup.valueChanges.subscribe((val) => {
      this.formUpdated.emit(val);
    });
  }

  onHasPrevApplicationChange(isYes: boolean) {
    if (isYes) {
      this.addControlToForm(this.formGroup, 'hasPrevClosedWithin90', true);
      this.formGroup.get('hasPrevClosedWithin90').setValidators(Validators.required);
    } else {
      this.removeControlFromForm(this.formGroup, 'hasPrevClosedWithin90', true);
    }
    this.formGroup.updateValueAndValidity();
  }

  setSaveButtonState() {
    //adding a timeount to wait for observer to subscribe (initializeStepper in application.component)
    if (this.formGroup.controls?.hasConfirmedDiagnosis.value === false &&
      this.formGroup.controls?.isAwaitingDiagnosis.value === false) {
      this.formGroup.get('hasConfirmedDiagnosis').setErrors({mustHaveAwaitingOrConfirmedDiagnosis: true});
      this.formGroup.get('isAwaitingDiagnosis').setErrors({mustHaveAwaitingOrConfirmedDiagnosis: true});
      this.disableSaveButton.emit(true);
    } else {
      this.formGroup.get('hasConfirmedDiagnosis').setErrors(null);
      this.formGroup.get('isAwaitingDiagnosis').setErrors(null);
      this.disableSaveButton.emit(false);
    }
  }

  onSameMailingAddressChange(hasSameMailingAddress: boolean) {
    if (hasSameMailingAddress) {
      this.removeControlFromForm(this.formGroup, 'mailingAddress', true);
    } else {
      this.addControlToForm(this.formGroup, 'mailingAddress', true);
      this.formGroup.get('mailingAddress').setValidators(Validators.required);
    }
    this.formGroup.updateValueAndValidity();
  }

  onIsAwaitingDiagnosisChange(isAwaitingDiagnosis: boolean) {
    if (isAwaitingDiagnosis) {
      this.addControlToForm(this.formGroup, 'waitingDiagnosis', true);
      this.formGroup.get('waitingDiagnosis').setValidators([Validators.required, Validators.maxLength(250)]);
      this.controlSubscriptions();
    } else {
      this.removeControlFromForm(this.formGroup, 'waitingDiagnosis', true);
    }
    this.formGroup.updateValueAndValidity();
    this.setSaveButtonState();
  }

  onHasConfirmedDiagnosisChange(hasConfirmedDiagnosis: boolean) {
    if (hasConfirmedDiagnosis) {
      this.addControlToForm(this.formGroup, 'confirmedDiagnosis', true);
      this.formGroup.get('confirmedDiagnosis').setValidators([Validators.required, Validators.maxLength(250)]);
      this.controlSubscriptions();
    } else {
      this.removeControlFromForm(this.formGroup, 'confirmedDiagnosis', true);
    }

    this.formGroup.updateValueAndValidity();
    this.setSaveButtonState();
  }

  controlSubscriptions() {
    if (!this.confirmedDiagnosisSubscription) {
      this.confirmedDiagnosisSubscription = this.formGroup.controls?.confirmedDiagnosis?.valueChanges.subscribe((x) => {
        this.characterCountConfirmedDiagnosis = x?.length ?? 0;
      });
    }
    if (!this.awaitingDiagnosisSubscription) {
      this.awaitingDiagnosisSubscription = this.formGroup.controls?.waitingDiagnosis?.valueChanges.subscribe((x) => {
        this.characterCountAwaitingDiagnosis = x?.length ?? 0;
      });
    }
  }

  onCitizenImmigrationStatusChange(citizenImmigrationStatus: string) {
    if (citizenImmigrationStatus === FiLegalStatus.citizen?.code) {
      this.identityDocumentsNeeded = {
        citizen: FiDocumentCategories.child.citizen,
      } as DocumentsUpload;
    } else {
      this.identityDocumentsNeeded = {
        permanentResident: FiDocumentCategories.child.permanentResident,
      } as DocumentsUpload;
    }
    this.cdr.detectChanges();
  }

  private setFormValue(): void {
    if (this.formGroup && this._childInfo) {
      this.formGroup.patchValue({
        firstName: this._childInfo.firstName,
        middleName: this._childInfo.middleName,
        lastName: this._childInfo.lastName,
        preferredName: this._childInfo.preferredName,
        hasSameMailingAddress: this._childInfo.hasSameMailingAddress,
        birthdate: this._childInfo.birthdate,
        residentialAddress: this._childInfo.residentialAddress,
        mailingAddress: this._childInfo.mailingAddress,
        gender: this._childInfo.gender,
        hasPrevApplication: this._childInfo.hasPrevApplication,
        hasPrevClosedWithin90: this._childInfo.hasPrevClosedWithin90,
        citizenImmigrationStatus: this._childInfo.citizenImmigrationStatus,
        livingArrangements: this._childInfo.livingArrangements,
        hasConfirmedDiagnosis: this._childInfo.hasConfirmedDiagnosis,
        confirmedDiagnosis: this._childInfo.confirmedDiagnosis,
        isAwaitingDiagnosis: this._childInfo.isAwaitingDiagnosis,
        waitingDiagnosis: this._childInfo.waitingDiagnosis,
      });
    }

    this.onHasPrevApplicationChange(this._childInfo.hasPrevApplication);

    if (this._childInfo.citizenImmigrationStatus)
      this.onCitizenImmigrationStatusChange(this._childInfo.citizenImmigrationStatus);

    if (this._childInfo.hasConfirmedDiagnosis) {
      this.characterCountConfirmedDiagnosis = this._childInfo.confirmedDiagnosis?.length ?? 0;
      this.onHasConfirmedDiagnosisChange(this._childInfo.hasConfirmedDiagnosis);
    }

    if (this._childInfo.isAwaitingDiagnosis) {
      this.characterCountAwaitingDiagnosis = this._childInfo.waitingDiagnosis?.length ?? 0;
      this.onIsAwaitingDiagnosisChange(this._childInfo.isAwaitingDiagnosis);
    }

    if (!this._childInfo.hasSameMailingAddress) {
      this.formGroup.get('mailingAddress').setValidators([Validators.required]);
      this.formGroup.get('mailingAddress').markAsTouched();
      if (!this._childInfo.mailingAddress) {
        this.formGroup.patchValue({
          mailingAddress: new AlbertaAddress(),
        });
      }
    }

    this.identityDocuments = [];
    if (this._childInfo.identityDocuments) {
      this._childInfo.identityDocuments.forEach((d) => this.identityDocuments.push({ ...d }));
      this.identityDocumentsFormControl.patchValue(this.identityDocuments);
    }

    this.medicalDocuments = [];
    if (this._childInfo.medicalDocuments) {
      this._childInfo.medicalDocuments.forEach((d) => this.medicalDocuments.push({ ...d }));
      this.medicalDocumentsFormControl.patchValue(this.medicalDocuments);
    }

    this.additionalDocuments = [];
    if (this._childInfo.additionalDocuments) {
      this._childInfo.additionalDocuments.forEach((d) => this.additionalDocuments.push({ ...d }));
      this.additionalDocumentsFormControl.patchValue(this.additionalDocuments);
    }

    this.setSaveButtonState();
    this.cdr.detectChanges();
  }

  onRemoveIdentityFile(document: Document) {
    if (!this.identityDocuments.some((d) => d.documentCategory === document.documentCategory && !d.isDeleted))
      this.cdr.detectChanges();
  }
  onIdentityFileUploaded(document: Document) {
    this.identityDocuments.push(document);
    this.identityDocumentsFormControl.patchValue(this.identityDocuments);
    if (
      this.identityDocuments.filter((d) => d.documentCategory === document.documentCategory && !d.isDeleted).length ===
      1
    )
      this.cdr.detectChanges();
  }

  onRemoveMedicalFile(document: Document) {
    if (!this.medicalDocuments.some((d) => d.documentCategory === document.documentCategory && !d.isDeleted))
      this.cdr.detectChanges();
  }

  onMedicalFileUploaded(document: Document) {
    this.medicalDocuments.push(document);
    this.medicalDocumentsFormControl.patchValue(this.medicalDocuments);
    if (
      this.medicalDocuments.filter((d) => d.documentCategory === document.documentCategory && !d.isDeleted).length === 1
    )
      this.cdr.detectChanges();
  }

  onRemoveAdditionalFile(document: Document) {
    if (!this.additionalDocuments.some((d) => d.documentCategory === document.documentCategory && !d.isDeleted))
      this.cdr.detectChanges();
  }

  onAdditionalFileUploaded(document: Document) {
    this.additionalDocuments.push(document);
    this.additionalDocumentsFormControl.patchValue(this.additionalDocuments);
    if (
      this.additionalDocuments.filter((d) => d.documentCategory === document.documentCategory && !d.isDeleted)
        .length === 1
    )
      this.cdr.detectChanges();
  }

  unsort() {
    return 0;
  }
  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
    this.confirmedDiagnosisSubscription?.unsubscribe();
    this.awaitingDiagnosisSubscription?.unsubscribe();
  }

  toggleReadOnly() {
    this.isReadOnly = !this.isReadOnly;
  }
}
